<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!--        <vuexy-logo />-->
        <span>
          <b-img
            :src="appLogoImage"
            style="width: 100px;"
          />
        </span>
        <!--        <h2 class="brand-text text-primary ml-1">-->
        <!--          Commercial Monitoring-->
        <!--        </h2>-->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div
            class="text-center"
            style="position:relative;"
          >
            <b-img
              :src="afridiaLogoImage"
              alt="afridia logo"
            />
          </div>

          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Bienvenu sur GIWU! 👋
          </b-card-title>
          <!--          <b-card-text class="mb-2">-->
          <!--            Please sign-in to your account and start the adventure-->
          <!--          </b-card-text>-->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Nom d'utilisateur"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder="john"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Mot de passe</label>
                  <!--                  <b-link :to="{name:'auth-forgot-password'}">-->
                  <!--&lt;!&ndash;                    <small>Forgot Password?</small>&ndash;&gt;-->
                  <!--                  </b-link>-->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->

              <div style="display: flex; justify-content: center; align-items: center;">
                <b-overlay
                  :show="show"
                  rounded="sm"
                  spinner-variant="primary"
                  spinner-small
                  class="d-inline-block"
                >
                  <!-- submit buttons -->
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                  >
                    Connexion
                  </b-button>
                </b-overlay>
              </div>
            </b-form>
          </validation-observer>

          <!-- divider -->
          <!--          <div class="divider my-2">-->
          <!--            <div class="divider-text">-->
          <!--              or-->
          <!--            </div>-->
          <!--          </div>-->

          <!-- social buttons -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { API_URL } from '@/helpers/global-scops'
import showToast from '@/helpers/methodes'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      show: false,
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/logo/login_background.png'),
      appLogoImage: require('@/assets/images/logo/logo_giwu_transparent.png'),
      afridiaLogoImage: require('@/assets/images/logo/afridia_logo.jpeg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.show = true
      this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            useJwt.login({
              username: this.username,
              password: this.password,
            })
              .then(response => {
                // const { userData } = response.data
                useJwt.setToken(response.data.access)
                useJwt.setRefreshToken(response.data.refresh)
                // localStorage.setItem('userData', JSON.stringify(userData))
                // this.$ability.update(userData.ability)
                this.$http.get(`${API_URL}users/user-auth`)
                  .then(serverResponse => {
                    // eslint-disable-next-line no-param-reassign
                    serverResponse.data.access = response.data.access
                    // eslint-disable-next-line no-param-reassign
                    serverResponse.data.refresh = response.data.refresh
                    const userData = serverResponse.data
                    userData.ability = [{
                      action: 'manage',
                      subject: 'all',
                    }]
                    userData.avatar = require('@/assets/images/portrait/user.png')
                    userData.extras = { eCommerceCartItemsCount: 5 }
                    userData.role = userData.profile
                    userData.fullName = `${userData.first_name} ${userData.last_name}`
                    localStorage.setItem('userData', JSON.stringify(userData))
                    this.$ability.update(userData.ability)
                    // ? This is just for demo purpose as well.
                    // ? Because we are showing eCommerce app's cart items count in navbar
                    this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                    // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                    this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                      .then(() => {
                        this.show = false
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: `Bienvenue ${userData.fullName || userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'primary',
                            text: `Vous vous êtes connecté avec succès en tant que ${userData.role}. Vous pouvez maintenant commencer à explorer !`,
                          },
                        })
                      })
                  })
              })
              .catch(error => {
                showToast(this, 'danger', 'Connexion', 'Mot de passe incorrect')
                this.$refs.loginForm.setErrors(error.response.data.error)
              })
          } else {
            showToast(this, 'danger', 'Connexion', 'Mot de passe incorrect')
            this.show = false
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
